import React, { useEffect, useState } from "react";
import {
  PulseAnimation,
  CardGalleryItem,
  FileUpload,
  TextButton,
  Loader,
  EmptyState,
  Heading,
  Modal,
  MessageModalLayout,
  Text,
  PopoverMenu,
  FormField,
  Box,
  Page,
  IconButton,
  Button,
  Cell,
  Layout,
  Input,
  WixDesignSystemProvider,
  Image,
  PageSection,
  Badge,
  MarketingLayout,
  AnnouncementModalLayout,
  Card,
  Radio,
  Popover,
  Notification,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import { ReactTagManager } from "react-gtm-ts";
import httpClient from "axios";
import { v4 } from "uuid";
import * as BC from "@certifiedcode/base-components";
import { useTranslation } from "react-i18next";
import WidgetImage from "./Images/pic_with_ai_widgets.png";
import Maya from "./Images/maya.png";
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const CONTENT = [
  "Unlimited generations",
  "Access to Variants",
  "Access Pic with AI via Wix Dashboard",
  "Funtionality of add Image Generator on your site",
];

const INTERVAL = 4000;

function App() {
  const { t } = useTranslation()
  ReactTagManager.init(tagManagerArgs);
  const [isFirstTime, setIsFirstTime] = React.useState(true);
  const [prompt, setPrompt] = React.useState("");
  const [modalRadio, setModalRadio] = React.useState("DALL·E-2");
  const [quality, setQuality] = React.useState("Standard");
  const [resolution, setResolution] = React.useState("1024x1024");
  const [style, setStyle] = React.useState("vivid");
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);
  const [isFairUsageModalOpen, setIsFairUsageModalOpen] = React.useState(false);
  const [advanceSettings, setAdvanceSettings] = React.useState(false);
  const [generations, setGenerations] = React.useState([]);
  // console.log(generations)
  const [isOptionsModalOpen, setIsOptionsModalOpen] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState(null as any);
  const [currentImageUrl, setCurrentImageUrl] = React.useState(null as any);
  const [currentImagePreview, setCurrentImagePreview] = React.useState("");
  const [instanceData, setInstanceData] = React.useState(null as any);
  const [currentVariantImageUrl, setCurrentVariantImageUrl] = React.useState(
    null as any
  );
  const [cursor, setCursor] = React.useState(null as any);

  const [index, setIndex] = React.useState<number>(0);
  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(false);
  console.log("isUpgradeBannerOpen", isUpgradeBannerOpen);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % CONTENT.length);
    }, INTERVAL);
    return () => clearInterval(sliderInterval);
  }, [CONTENT, INTERVAL]);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    setGenerationsFromApi();
  }, []);

  useEffect(() => {
    if (currentVariantImageUrl !== null) {
      getGeneration("variants");
    }
  }, [setCurrentVariantImageUrl]);

  useEffect(() => {
    if (currentImageUrl !== null) {
      createUpscaled();
    }
  }, [currentImageUrl]);

  // create ref
  const inputAreaRef = React.useRef(null);

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=a417ef4e-3194-492a-8fcc-a85a1d7e8c03&redirectUrl=https://certifiedcode.editorx.io/pic-with-ai/_functions/@certifiedcode/base-backend/auth`;
  }

  const BASE_URL = `https://certifiedcode.editorx.io/pic-with-ai/_functions`;

  async function uploadMyFile(uploadUrl: string, fileContent: any, id: string) {
    const params = { filename: id + ".png" };
    const headers = {
      "Content-Type": "application/octet-stream",
    };

    const uploadResponse = await httpClient.put(uploadUrl, fileContent, {
      headers,
      params,
    });
    return uploadResponse;
  }

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => { 
        if (data.hash && data.email) {
          window?.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      });
  }

  function createUpscaled() {
    setIsProcessing(true);
    fetch(BASE_URL + "/upscale", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        imageUrl: currentImageUrl,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setGenerationsFromApi();
        setIsProcessing(false);
      });
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setInstanceData(data);
          setIsUpgradeBannerOpen((data as any)["instance"]["isFree"]);
        } else {
          setIsModalOpen(true);
        }
      })
      .catch((error) => {
        setIsModalOpen(true);
      });
  }

  function setGenerationsFromApi() {
    fetch(BASE_URL + "/generations", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.files) {
          setGenerations(data.files);
        }
      });
  }

  function getGeneration(type: string) {
    setIsFirstTime(false);
    setIsProcessing(true);
    getGenerationFromApi(type);
  }

  async function getGenerationFromApi(type: string) {
    var requestBody = {
      prompt,
      mask: "",
      image: "",
      quality,
      resolution,
      model: modalRadio,
      style,
    };
    if (type === "variations") {
      if (currentVariantImageUrl) {
        requestBody.image = currentVariantImageUrl;
      } else {
        const uploadData = await fetch(BASE_URL + "/uploadurl", {
          method: "GET",
          headers: {
            Authorization: instance || "",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("object", data.success);
            if (data.success === false) {
              setIsModalOpen(true);
              setIsProcessing(false);
            }
            return data;
          })
          .catch((error) => {
            setIsModalOpen(true);
            setIsProcessing(false);
          });

        const id = v4();
        const file = await uploadMyFile(uploadData.uploadUrl, currentImage, id);
        requestBody.image = (file as any).data.file.url;
      }
      const data = await fetch(BASE_URL + "/variations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: instance || "",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success === false) {
            setIsModalOpen(true);
            setIsProcessing(false);
          }
          return data;
        })
        .catch((error) => {
          setIsModalOpen(true);
          setIsProcessing(false);
        });
      if (data.data) {
        setGenerations(data.data.concat(generations));
        setIsProcessing(false);
      } else {
        if (data.canRetry) {
          getGenerationFromApi(type);
        } else {
          if (data.isUpgradeRequired) {
            setIsFairUsageModalOpen(true);
            setIsProcessing(false);
          } else {
            if (data.success === false) {
              setIsModalOpen(true);
              setIsProcessing(false);
            }
          }
        }
      }
      setCurrentVariantImageUrl(null);
    }
    if (type === "generations") {
      fetch(BASE_URL + "/generations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: instance || "",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            setGenerations(data.data.concat(generations));
            setIsProcessing(false);
          } else {
            if (data.canRetry) {
              getGenerationFromApi(type);
            } else {
              if (data.isUpgradeRequired) {
                setIsFairUsageModalOpen(true);
                setIsProcessing(false);
              } else {
                if (data.success === false) {
                  setIsModalOpen(true);
                  setIsProcessing(false);
                }
              }
            }
          }
        })
        .catch((error) => {
          setIsModalOpen(true);
          setIsProcessing(false);
        });
    }
  }

  const isPremiumApp = true;

  if (!instanceData) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page>
          <Page.Content>
            <Box
              padding="10px"
              align="center"
              verticalAlign="middle"
              height={"90vh"}
              direction="vertical"
            >
              <Loader text={t('loading')} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  if (!instance) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page>
          <Page.Content>
            <Box
              padding="10px"
              align="center"
              verticalAlign="middle"
              height={"90vh"}
              direction="vertical"
            >
              <Loader
                status="error"
                text={t('error-msg')}
              />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const Generation = (gen: any) => {
    // console.log(gen)
    var type = gen["labels"]?.find((v: string) => v !== "Pic with AI");
    if (gen["labels"]?.find((v: string) => v === "Pic with AI")) {
      type = "Generation";
    }
    if (gen?.displayName?.includes("Variation")) {
      type = "Variant";
    }
    if (gen?.displayName?.includes("upscale")) {
      type = "Upscale";
    }
    const badge = {
      Generation: (
        <Badge size="small" skin="general">
          {t('generation-badge')}
        </Badge>
      ),
      Upscale: (
        <Badge size="small" skin="neutralSuccess">
         {t('upscaled-badge')} 
        </Badge>
      ),
      Variant: (
        <Badge size="small" skin="warningLight">
          {t('Variant-badge')}
        </Badge>
      ),
    };
    return (
      <CardGalleryItem
        badge={(badge as any)[type]}
        backgroundImageUrl={gen["url"]}
        primaryActionProps={{
          label: t('label-download'),
          onClick: (e: any) => {
            console.log(e["target"]);
            // if any of its higher level includes element with style="background-image: url(gen[url]);", then open the image, recursively
            var element = e["target"];
            while (element) {
              if (
                element.style &&
                element.style.backgroundImage &&
                element.style.backgroundImage.includes(gen["url"])
              ) {
                window.open(gen["url"], "_blank");
                break;
              }
              element = element.parentElement;
            }
          },
        }}
        suffix={
          <Box direction="horizontal" verticalAlign="middle" gap="SP1">
            <PopoverMenu
                triggerElement={({
                  toggle,
                  close,
                }: {
                  toggle: () => void;
                  close: () => void;
                }) => (
                <IconButton
                  onClick={toggle}
                  onMouseLeave={close}
                  size="small"
                  priority="secondary"
                >
                  <Icons.More />
                </IconButton>
              )}
            >
              <PopoverMenu.MenuItem
                text="Create Variants"
                disabled={isProcessing}
                prefixIcon={<Icons.MagicWand />}
                onClick={() => {
                  setCurrentVariantImageUrl(gen["url"]);
                  if (!!(instanceData as any)["instance"]["isFree"]) {
                    setIsUpgradeModalOpen(true);
                  } else {
                    getGeneration("variations");
                  }
                }}
              />
              <PopoverMenu.MenuItem
                disabled={isProcessing}
                text="Upscale 2X"
                prefixIcon={<Icons.Maximize />}
                onClick={() => {
                  setCurrentImageUrl(gen["url"]);
                }}
              />
              {/* <PopoverMenu.MenuItem
          disabled={isProcessing}
            text="Delete"
            skin="destructive"
            prefixIcon={<Icons.Delete />}
          /> */}
            </PopoverMenu>
            <IconButton
              skin="standard"
              size="small"
              onClick={() => {
                window.open(gen["url"], "_blank");
              }}
            >
              <Icons.Download />
            </IconButton>
          </Box>
        }
        size="small"
        aspectRatio="1"
      />
    );
  };

  const upgradeUrl = `https://www.wix.com/apps/upgrade/${"a417ef4e-3194-492a-8fcc-a85a1d7e8c03"}?appInstanceId=${
    (instanceData as any)["instance"]["instanceId"]
  }`;

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>
        {t('upgradeMessage', { content: t(`content.${index}`) })}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeUrl, "_blank");
          }}
        >
         {t('upgrade-button')}
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Page
        height="100vh"
        className={`${isUpgradeBannerOpen ? "App-header-notification" : ""}`}
      >
        <Page.Header
          title={
            <Box gap={"SP2"} alignItems="middle">
              <Heading>{t('pic-title')}</Heading>
              <Badge
                size="tiny"
                skin={
                  !!(instanceData as any)["instance"]["isFree"]
                    ? "neutral"
                    : "premium"
                }
              >
                {!!(instanceData as any)["instance"]["isFree"] ? t('free-badge') : t('pro-badge')}
              </Badge>
            </Box>
          }
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              <Button
                skin="premium"
                prefixIcon={<Icons.PremiumFilled />}
                onClick={() => setIsUpgradeModalOpen(true)}
              >
                {(instanceData as any)?.instance?.billing?.packageName === "pro"
                  ? t('unlimited-gen')
                  : (instanceData as any)?.instance?.billing?.packageName ===
                  t('unlimited')
                  ? t('manage-plan')
                  : t('more-gen')}
              </Button>
              <Button
                prefixIcon={<Icons.Reviews />}
                skin="inverted"
                as="a"
                href="https://bridget.reviews.certifiedcode.us/a417ef4e-3194-492a-8fcc-a85a1d7e8c03"
                target="_blank"
              >
                {t('review-button')}
              </Button>
              <Button
                prefixIcon={<Icons.RevertReset />}
                disabled={prompt.length === 0}
                onClick={() => {
                  setPrompt("");
                  setGenerations([]);
                }}
              >
                {t('clear-button')}
              </Button>
            </Box>
          }
        />
        <Page.Content>
          <Modal isOpen={isOptionsModalOpen}>
            <MessageModalLayout
              illustration={
                <Image src={currentImagePreview} fit="contain"></Image>
              }
              onCloseButtonClick={() => {
                setIsOptionsModalOpen(false);
              }}
              secondaryButtonText={t('cancel-button')} //"Edit"
              secondaryButtonProps={{
                prefixIcon: <Icons.X />,
                onClick: () => {
                  setIsOptionsModalOpen(false);
                },
              }}
              primaryButtonProps={{
                prefixIcon: <Icons.MagicWandSmall />,
                onClick: () => {
                  setIsOptionsModalOpen(false);
                  if (!!(instanceData as any)["instance"]["isFree"]) {
                    setIsUpgradeModalOpen(true);
                  } else {
                    getGeneration("variations");
                  }
                },
              }}
              primaryButtonText={t('variation-button')}
              title={t('variation-title')}
              content={
                <Text>
                  {t('variation-text')}
                </Text>
              }
            />
            ;
          </Modal>
          <Modal isOpen={isFairUsageModalOpen}>
            <MessageModalLayout
              onCloseButtonClick={() => {
                setIsFairUsageModalOpen(false);
              }}
              primaryButtonOnClick={() => {
                setIsFairUsageModalOpen(false);
              }}
              primaryButtonText="Got it"
              secondaryButtonText={t('upgrade-app-button')}
              secondaryButtonOnClick={() => {
                window.open(upgradeUrl);
              }}
              title={t('error-in-gen-title')}
              content={
                <Text>
                  {t('error-in-gen-text')}
                </Text>
              }
              footnote={
                <Text size="small">
                  {t('learn-more-text')}{" "}
                  <a
                    href="https://support.certifiedcode.us/en/articles/7851390-how-pic-with-ai-s-plans-work"
                    target="_blank"
                  >
                    {t('here')}
                  </a>
                  .
                </Text>
              }
            />
          </Modal>
          <Modal
            isOpen={isUpgradeModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsUpgradeModalOpen(false)}
          >
            <AnnouncementModalLayout
              theme="premium"
              title={t('start-gen-title')}
              primaryButtonText={t('upgrade-button')}
              primaryButtonOnClick={() => {
                window.open(upgradeUrl);
              }}
              linkText={t('do-later')}
              linkOnClick={() => setIsUpgradeModalOpen(false)}
              onCloseButtonClick={() => setIsUpgradeModalOpen(false)}
            >
              <Text>
                {t('start-gen-text')}
              </Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal isOpen={isModalOpen} screen="desktop">
            <MessageModalLayout
              primaryButtonText={t('refresh-button')}
              primaryButtonOnClick={() => window.location.reload()}
              title={t('something-wrong')}
              content={
                <Text>
                 {t('something-wrong-text')}
                </Text>
              }
            />
          </Modal>

          <Layout>
            {/* <Cell span={12}>
              <Card>
                <MarketingLayout
                  title="Introducing: Widgets"
                  description="Widgets are a new way to generate pictures with AI. Your site visitors and members can now generate pictures with AI directly from your website, without leaving it."
                  actions={<Button onClick={() => { window.Intercom("showArticle", 7993569) }} size="small">Learn more</Button>}
                  image={<Image transparent fit="contain" src={WidgetImage} />}
                  badge={<Badge size="small" skin="premium">{(instanceData as any)['instance']['availablePlans'].find((v: string) => v === "unlimited") ? "Unlocked!" : "Only for Unlimited plan"}</Badge>}
                />
              </Card>
            </Cell> */}
            <Cell span={12}>
              <Box
                display="grid"
                gridTemplateColumns={"6fr 1fr"}
                verticalAlign="middle"
              >
                <FormField label={t('label-enter')}>
                  {isProcessing ? (
                    <Input
                      status="loading"
                      readOnly={true}
                      value={prompt}
                      size="large"
                      border="round"
                    />
                  ) : (
                    <PulseAnimation
                      active={isFirstTime}
                      fluid={true}
                      color="B10"
                      borderRadius="18px"
                    >
                      <Input
                        border="round"
                        value={prompt}
                        maxLength={100}
                        size="large"
                        suffix={[
                          <Box
                            height={"100%"}
                            direction="horizontal"
                            verticalAlign="middle"
                          >
                            <Button
                              size="tiny"
                              onClick={() => getGeneration("generations")}
                              disabled={prompt.length === 0}
                            >
                              {t('gen-button')}
                            </Button>
                          </Box>,
                        ]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrompt(e.target.value)}
                      />
                    </PulseAnimation>
                  )}
                </FormField>
                <Popover
                  showArrow
                  animate
                  shown={advanceSettings}
                  onClickOutside={() => setAdvanceSettings(false)}
                  appendTo="window"
                  placement="auto"
                  style={{ marginTop: "22px" }}
                >
                  <Popover.Element>
                    <Button
                      style={{ marginLeft: "10px" }}
                      size="tiny"
                      onClick={() => setAdvanceSettings(!advanceSettings)}
                    >
                      {t('advanced-button')} <Icons.Settings />
                    </Button>
                  </Popover.Element>
                  <Popover.Content>
                    <Card>
                      <Card.Content>
                        <Box direction="vertical" gap="SP2">
                          <Radio
                            onChange={() => setModalRadio("DALL·E-3")}
                            id="DALL·E-3"
                            name="DALL·E-3"
                            value="DALL·E-3"
                            checked={modalRadio === "DALL·E-3"}
                            label={
                              <Text size="small" secondary>
                                {t('dall-e-3')}
                              </Text>
                            }
                          />
                          <Radio
                            onChange={() => setModalRadio("DALL·E-2")}
                            id="DALL·E-2"
                            name="DALL·E-2"
                            value="DALL·E-2"
                            checked={modalRadio === "DALL·E-2"}
                            label={
                              <Text size="small" secondary>
                                {t('dall-e-2')}
                              </Text>
                            }
                          />
                          {modalRadio === "DALL·E-3" && (
                            <>
                              <Box display="flex" marginBottom={"6px"}>
                                <Text
                                  style={{
                                    margin: "0",
                                    fontSize: "14px",
                                    color: "#333853",
                                  }}
                                >
                                  {t('quality-text')}
                                </Text>
                                {(instanceData as any)["instance"][
                                  "isFree"
                                ] && (
                                  <Box marginLeft="12px">
                                    <Badge skin="premium">{t('premium-badge')}</Badge>
                                  </Box>
                                )}
                              </Box>
                              <Radio
                                onChange={() => setQuality("Standard")}
                                id="Standard"
                                name="Standard"
                                value="Standard"
                                checked={quality === "Standard"}
                                label={
                                  <Text size="small" secondary>
                                   {t('standard-text')}
                                  </Text>
                                }
                                disabled={
                                  !(instanceData as any)["instance"][
                                    "isFree"
                                  ] === false
                                }
                              />

                              <Radio
                                onChange={() => setQuality("HD")}
                                id="HD"
                                name="HD"
                                value="HD"
                                checked={quality === "HD"}
                                label={
                                  <Text size="small" secondary>
                                    {t('hd-text')}
                                  </Text>
                                }
                                disabled={
                                  !(instanceData as any)["instance"][
                                    "isFree"
                                  ] === false
                                }
                              />

                              <Text
                                style={{
                                  margin: "0",
                                  fontSize: "14px",
                                  color: "#333853",
                                }}
                              >
                                {t('resolution-text')}
                              </Text>
                              {(instanceData as any)["instance"]["isFree"] && (
                                <Box marginLeft="12px">
                                  <Badge skin="premium">{t('premium-badge')}</Badge>
                                </Box>
                              )}
                              <Radio
                                onChange={() => setResolution("1024x1024")}
                                id="1024x1024"
                                name="1024x1024"
                                value="1024x1024"
                                checked={resolution === "1024x1024"}
                                label={
                                  <Text size="small" secondary>
                                    {t('size-text')}
                                  </Text>
                                }
                                disabled={
                                  !(instanceData as any)["instance"][
                                    "isFree"
                                  ] === false
                                }
                              />
                              <Radio
                                onChange={() => setResolution("1024×1792")}
                                id="1024×1792"
                                name="1024×1792"
                                value="1024×1792"
                                checked={resolution === "1024×1792"}
                                label={
                                  <Text size="small" secondary>
                                    {t('size-text-1')}
                                  </Text>
                                }
                                disabled={
                                  !(instanceData as any)["instance"][
                                    "isFree"
                                  ] === false
                                }
                              />
                              <Radio
                                onChange={() => setResolution("1792×1024")}
                                id="1792×1024"
                                name="1792×1024"
                                value="1792×1024"
                                checked={resolution === "1792×1024"}
                                label={
                                  <Text size="small" secondary>
                                    {t('size-text-2')}
                                  </Text>
                                }
                                disabled={
                                  !(instanceData as any)["instance"][
                                    "isFree"
                                  ] === false
                                }
                              />

                              <Text
                                style={{
                                  margin: "0",
                                  fontSize: "14px",
                                  color: "#333853",
                                }}
                              >
                                {t('style-text')}
                              </Text>
                              {(instanceData as any)["instance"]["isFree"] && (
                                <Box marginLeft="12px">
                                  <Badge skin="premium">{t('premium-badge')}</Badge>
                                </Box>
                              )}
                              <Radio
                                onChange={() => setStyle("vivid")}
                                id="vivid"
                                name="vivid"
                                value="vivid"
                                checked={style === "vivid"}
                                label={
                                  <Text size="small" secondary>
                                    {t('vivid-text')}
                                  </Text>
                                }
                                disabled={
                                  !(instanceData as any)["instance"][
                                    "isFree"
                                  ] === false
                                }
                              />
                              <Radio
                                onChange={() => setStyle("natural")}
                                id="natural"
                                name="natural"
                                value="natural"
                                checked={style === "natural"}
                                label={
                                  <Text size="small" secondary>
                                    {t('natural-text')}
                                  </Text>
                                }
                                disabled={
                                  !(instanceData as any)["instance"][
                                    "isFree"
                                  ] === false
                                }
                              />
                            </>
                          )}
                        </Box>
                      </Card.Content>
                    </Card>
                  </Popover.Content>
                </Popover>
              </Box>
            </Cell>
            <Cell span={12}>
              <Layout gap="SP2">
                {generations.slice(0, 4).map((gen, index) => {
                  return <Cell span={3}>{Generation(gen)}</Cell>;
                })}
              </Layout>
              <EmptyState
                theme="page-no-border"
                title={
                  generations.length > 0
                    ? t('own-pic-gen')
                    : t('own-prompt-gen')
                }
                subtitle={
                  generations.length > 0
                    ? t('own-upload-prompt-gen')
                    : t('own-upload-gen')
                }
              >
                <FileUpload
                  accept=".png"
                  onChange={(e: any) => {
                    // save the image to state
                    const file = e[0];
                    setCurrentImage(e[0]);
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                      setCurrentImagePreview(reader.result as string);
                    };
                    setIsOptionsModalOpen(true);
                  }}
                >
               {({ openFileUploadDialog }: { openFileUploadDialog: () => void }) => (
                    <TextButton
                      prefixIcon={<Icons.Upload />}
                      onClick={
                        openFileUploadDialog
                        // openFileUploadDialog
                      }
                    >
                     {t('upload-pic-text')}
                    </TextButton>
                  )}
                </FileUpload>
              </EmptyState>
              <Layout gap="SP2">
                {generations.slice(4).map((gen, index) => {
                  return <Cell span={2}>{Generation(gen)}</Cell>;
                })}
              </Layout>
            </Cell>
            <Cell>
              <Text size="tiny" skin="disabled">
               {t('image-secure-msg')}{" "}
                <a
                  href="https://support.wix.com/en/article/wix-media-accessing-the-media-manager"
                  target="_blank"
                >
                 {t('learn-more')}
                </a>
              </Text>
            </Cell>
            <Cell span={12}>
              <BC.Offers
                appId="a417ef4e-3194-492a-8fcc-a85a1d7e8c03"
                siteId={instanceData?.site?.siteId}
              />
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
